DataStore = Class.create();
DataStore.prototype = {
	initialize: function() {
		this.functions = {};

		DataStore.register = this.register.bind(this);
	},

	register: function(name, data) {
		if (typeof data == 'function') {
			this.functions[name] = data;
			DataStore[name] = this.functions[name].bind(this);
		} else {
			DataStore[name] = data;
		}
	}
};

new DataStore();