Platform = Class.create({

	bootstrap: async function() {
		await this.initPlatform();
		
		this.available.forEach(name => {
			var func = this.factory(name);
			if (func) {
				Application[name] = func;
			}
		});

		Application.api = this.api + this.account.client + '/' + this.account.username + '/' + this.account.password + '/' + this.credentials.stage + '/' + this.credentials.actor + '/';

		Settings.client = this.account.client;
		Settings.salon = this.credentials.actor;
		Settings.rights = this.credentials.rights || 0;
		Settings.capabilities = this.account.capabilities;

		await Application.setupDatabase(Settings.client, Settings.salon);
	},

	initPlatform: function() {}
});
