(() => {
    function replaceChildren(...new_children) {
        const { childNodes } = this;
        while (childNodes.length) {
            childNodes[0].remove();
        }
        this.append(...new_children);
    }

    if (!Document.prototype.replaceChildren) Document.prototype.replaceChildren = replaceChildren;
    if (!DocumentFragment.prototype.replaceChildren) DocumentFragment.prototype.replaceChildren = replaceChildren;
    if (!Element.prototype.replaceChildren) Element.prototype.replaceChildren = replaceChildren;
})();