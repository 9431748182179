DataCache = {

    available: function() {
        return new Promise((resolve, reject) => {
            if (!Database.connection) {
                resolve(false);
                return;
            }

            if (Database.upgraded) {
                resolve(false);
                return;
            }

            Database.retrieve('settings', 'cached').then(value => {
                resolve(value);
            }).catch(() => {
                resolve(false);
            });
        });
    },

    sync: function() {
        return new Promise((resolve, reject) => {
            new Ajax.Request(Application.api + 'retrieveSettings', {
                method: 'get',
                evalJSON: 'force',
                onSuccess: function(transport) {
                    if (transport.status == 0) {
                        reject();
                        return;
                    }

                    if (transport.responseJSON) {
                        DataCache.store(transport.responseJSON);
                        resolve();
                    }
                }.bind(this),

                onFailure: function(transport) {
                    reject();
                }.bind(this)
            });												
        });
    },

    store: function(response) {
        if (Database.connection) {
            Database.store('settings', [
                { name: 'points', value: response.settings.points },
                { name: 'register', value: response.settings.register },
            ]);

            Database.store('units', response.data.units);
            Database.store('discounts', response.data.discounts);
            Database.store('categories', response.data.categories)
            Database.store('treatments', response.data.treatments)
            Database.store('products', response.data.products)
            Database.store('groups', response.data.groups);
            Database.store('subscriptions', response.data.subscriptions);
            Database.store('employees', response.data.employees);
            Database.store('suppliers', response.data.suppliers);
            Database.store('filters', response.data.filters.presets);
            Database.store('terminals', response.data.terminals);
            Database.store('salons', response.data.salons);

            Database.store('settings', [
                { name: 'cached', value: true }
            ]);
        }

        response.data.treatments = { categories: treeify(response.data.categories.filter(item => item.type == 0)), items: response.data.treatments };
        response.data.products = { categories: treeify(response.data.categories.filter(item => item.type == 1)), items: response.data.products };
        response.data.groups = { treatments: response.data.groups.filter(item => item.type == 0), products:	response.data.groups.filter(item => item.type == 1) };
        response.data.salons = { items: response.data.salons, current: response.data.salons.filter(i => i.id == Settings.salon).pop(), list: (() => { var d = {}; response.data.salons.forEach(i => d[i.id] = i.fullname); return d; })() };
      

        function fillDefaultSupplier(defaultSupplier, supplier, list) {
            return list.map(item => {
                item.defaultSupplier = supplier !== null ? supplier : defaultSupplier;
                
                if (item.children) {
                    item.children = fillDefaultSupplier(item.defaultSupplier, item.supplier, item.children);
                }

                return item;
            });
        }

        response.data.products.categories = fillDefaultSupplier(null, null, response.data.products.categories);


        Object.assign(DataStore, response.data);
        Object.assign(Settings, response.settings);

        return response;
    },

    retrieve: function() {
        return Promise.all([
            Database.retrieve('units'), 
            Database.retrieve('discounts'), 
            Database.retrieve('categories'), 
            Database.retrieve('treatments'), 
            Database.retrieve('products'), 
            Database.retrieve('groups'), 
            Database.retrieve('subscriptions'), 
            Database.retrieve('employees'), 
            Database.retrieve('suppliers'), 
            Database.retrieve('filters'), 
            Database.retrieve('salons'), 
            Database.retrieve('settings'), 
            Database.retrieve('terminals'), 
        ]).then(function(values) {
            var result = {
                data: {},
                settings: {}
            };
            
            values[1].sort((a, b) => a.position - b.position);
            values[2].sort((a, b) => a.order - b.order);
            values[3].sort((a, b) => typeof a.position != 'undefined' ? a.position - b.position : a.id - b.id);
            values[4].sort((a, b) => a.position - b.position);
            values[5].sort((a, b) => typeof a.position != 'undefined' ? a.position - b.position : a.id - b.id);
            values[6].sort((a, b) => a.position - b.position);
            values[7].sort((a, b) => typeof a.position != 'undefined' ? a.position - b.position : a.id - b.id);

            result.data.units = values[0];
            result.data.discounts = values[1];
            result.data.categories = values[2];
            result.data.treatments = { categories: treeify(values[2].filter(item => item.type == 0)), items: values[3] };
            result.data.products = { categories: treeify(values[2].filter(item => item.type == 1)), items: values[4] };
            result.data.groups = { treatments:	values[5].filter(item => item.type == 0), products:	values[5].filter(item => item.type == 1) };
            result.data.subscriptions = values[6];
            result.data.employees = values[7];
            result.data.suppliers = values[8];
            result.data.filters = { presets: values[9] };
            result.data.terminals = values[12];
            result.data.salons = { items: values[10], current: values[10].filter(i => i.id == Settings.salon).pop(), list: (() => { var d = {}; values[10].forEach(i => d[i.id] = i.fullname); return d; })() };
            
            result.settings.points = values[11].filter(i => i.name == 'points').pop().value;
            result.settings.register = values[11].filter(i => i.name == 'register').pop().value;


            function fillDefaultSupplier(defaultSupplier, supplier, list) {
                return list.map(item => {
                    item.defaultSupplier = supplier !== null ? supplier : defaultSupplier;
                    
                    if (item.children) {
                        item.children = fillDefaultSupplier(item.defaultSupplier, item.supplier, item.children);
                    }

                    return item;
                });
            }

            result.data.products.categories = fillDefaultSupplier(null, null, result.data.products.categories);

            Object.assign(DataStore, result.data);
            Object.assign(Settings, result.settings);
        }).catch(e => {

            /* 
                Special case for development environment,
                since we always uses the same database version
                we may need to manually upgrade
            */

            if (Database.connection.version == 1) {
                console.error(
                    'There is an issue with accessing the database in the development environment, ' +
                    'trying to upgrade the database. Most likely this is caused by adding a new ' +
                    'object store to the database. Try deleting the database to recreate it.'
                );
            }

            throw e;
		});
    }
}