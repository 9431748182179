PlatformBrowser = Class.create(Platform, {
	available:	[
		'supports', 'reload', 'close', 'playSound', 'openInBrowser',
		'storeInLocker', 'getFromLocker', 'clearActions', 'addActions',
		'connectionStatusUpdate', 'connectionStatusShow', 'displayReceipt',

		/* SalonhubPOS */
		'getDevices', 'storeDevicesSettings', 'getExternalDeviceCapabilities', 'testDevice'
	],

	initPlatform: function() {
		return new Promise(response => {
		
			/* Setup bridge */
			if (window.parent != window) window.addEventListener('message', this.message.bindAsEventListener(this));

			/* Setup display broadcast channel */
			if ('BroadcastChannel' in window) {
				this.externalDisplay = new BroadcastChannel('external_display');
			}

			/* Settings */
			this.api = Settings.api;

			this.command('initialize', data => {
				var account = data.account;
				var credentials = data.credentials;

				if (account.client && account.login.username && account.login.password && account.permissions.capabilities &&
					credentials.stage && credentials.actor)
				{
					this.account = {
						client:			account.client,
						username:		account.login.username,
						password:		account.login.password,
						code:			account.permissions.code,
						capabilities:	account.permissions.capabilities
					}

					this.credentials = {
						stage:			credentials.stage,
						actor:			credentials.actor,
						rights:			credentials.rights || 0
					};

					this.title = credentials.title;
					this.salons = account.permissions.salons;
					this.guid = data.guid;
					this.api = data.api;
					this.options = data.options;
					this.tokens = data.tokens;
					this.keys = data.keys;

					this.session = new Session({
						applicationToken:		this.tokens['Session.*'] || null,
						sessionToken:			this.tokens['Application.*'] || null,
					})
				}


				if (window.top != window) this.available.push('enableOverlay');
				if (window.top != window) this.available.push('disableOverlay');
				if (window.top != window) this.available.push('initializeWrapper');
				if (window.top != window) this.available.push('activateView');

				if (data.capabilities.paymentTransaction) this.available.push('paymentTransaction');
				if (data.capabilities.kickCashDrawer) this.available.push('kickCashDrawer');
				if (data.capabilities.displayLines) this.available.push('displayLines');
				if (data.capabilities.displayDefault) this.available.push('displayDefault');
				if (data.capabilities.printReceipt) this.available.push('printReceipt');

				/* Globals */
				Settings.location = '';
				Settings.autoPrintReceipt = data.options.autoPrintReceipt;

				response();
			});
		});
	},

	message: async function(event) {
		if (event.data.response) {
			if (this._callbacks[event.data.response]) {
				this._callbacks[event.data.response].apply(this, event.data.result);
			}
		}

		if (event.data.command) {
			switch (event.data.command) {
				case "go":			this.switchTo.apply(this, event.data.data); break;
				case "reload":		window.location.reload(); break;
				case "logout":		this.logout(); break;
				case "lock":		Application.current._lock(); break;
				case "unlock":		Application.current._unlock(event.data.data[0]); break;
				case "event":		document.fire(event.data.data[0].event); break;
				case "callback":	let fn = this._callbacks[event.data.data[0].callback];
									let result;

									if (fn) {
										if (fn.constructor.name === "AsyncFunction") {
											result = await fn.apply(this);
										} else {
											result = fn.apply(this);
										}

										if (result) {
											if (event.data.callback) {
												event.source.postMessage({
													response: 	event.data.callback,
													result:		result
												}, '*');
											}
										}
									}
									 
									break;
			}
		}
	},

	command: function() {
		if (arguments.length < 1) return;

		var command = arguments[0];
		var data = null;
		var callback = null;
		var id = null;

		if (typeof arguments[arguments.length - 1] == 'function') {
			callback = arguments[arguments.length - 1];
			data = Array.prototype.slice.call(arguments).slice(1,-1);
		} else {
			data = Array.prototype.slice.call(arguments).slice(1);
		}

		if (callback) {
			id = this.registerCallback(callback)
		}

		window.parent.postMessage({
			command:	command,
			data:		data,
			callback:	id
		}, '*');
	},

	registerCallback: function(callback) {
		if (typeof callback == 'function') {
			id = uniqueid();
			if (typeof this._callbacks == 'undefined') this._callbacks = {};
			this._callbacks[id] = callback;
			return id;
		}

		return callback;
	},

	factory: function(id) {
		return this.apis[id].bind(this);
	},

	apis: {
		supports: function(name, callback) {
			if (typeof callback != 'function') {
				return new Promise(resolve => {
					this.command('supports', name, resolve);
				})
			}

			this.command('supports', name, callback);
		},

		reload: function() {
			this.command('reload');
		},

		close: function() {
			this.command('close');
		},

		printReceipt: function(receipt, callback) {
			this.command('printReceipt', receipt, callback);
		},

		kickCashDrawer: function() {
			this.command('kickCashDrawer');
		},

		displayDefault: function() {
			this.command('displayDefault');
		},

		displayLines: function(lineOne, lineTwo) {
			this.command('displayLines', lineOne, lineTwo);
		},

		displayReceipt: function(receipt) {
			if (this.externalDisplay) {
				this.externalDisplay.postMessage({
					type:	'receipt',
					data:	receipt
				});
			}
		},

		paymentTransaction: function(receipt, callback) {
			this.command('paymentTransaction', receipt, callback);
		},

		enableOverlay: function() {
			this.command('enableOverlay');
		},

		disableOverlay: function() {
			this.command('disableOverlay');
		},

		initializeWrapper: function(data, response) {
			this.command('initializeWrapper', data, response);
		},

		activateView: function(id, response) {
			this.command('activateView', id, response);
		},

		storeInLocker: function(key, value) {
			var id = 'salonhub-' + Settings.client + (Settings.salon ? '-' + Settings.salon : '');

			if ('localStorage' in window) {
				window.localStorage.setItem(id + '-' + key, JSON.stringify(value));
				return true;
			}
		},

		getFromLocker: async function(key) {
			var id = 'salonhub-' + Settings.client + (Settings.salon ? '-' + Settings.salon : '');

			if ('localStorage' in window) {
				var data = window.localStorage.getItem(id + '-' + key);
				if (data) {
					return JSON.parse(data);
				}
			}
		},

		playSound: function(name) {
			try {
				let sound = new Audio(_ROOT + "../assets/" + name + ".mp3");
				sound.play();
			} catch (e) {
			}
		},

		openInBrowser: function(url, options) {
			if (options && options.type == 'oauth') {
				let name = options.partition || 'oauth_default'
				let features = [];

				if (options.width) {
					features.push('width=' + options.width);
					features.push('top=' + Math.round((screen.height - options.height) / 2));
				}

				if (options.height) {
					features.push('height=' + options.height);
					features.push('left=' + Math.round((screen.width - options.width) / 2));
				}

				features.push('menubar=no');
				features.push('resizeable=yes');
				features.push('status=no');

				window.open(url, name, features.join(','));
				return;
			}

			window.open(url);
		},

		connectionStatusUpdate: function(state, method) {
			this.command('connectionStatusUpdate', state, method);
		},

		connectionStatusShow: function() {
			this.command('connectionStatusShow');
		},

		clearActions: function() {
			this.command('clearActions');
		},

		addActions: function(item) {
			if (item.type == 'action') {
				item.action = this.registerCallback(item.action);
			}

			this.command('addActions', item);
		},

		getExternalDeviceCapabilities: function(data) {
			return new Promise(resolve => {
				this.command('getExternalDeviceCapabilities', data, resolve);
			})
		},

		getDevices: function(forceUpdate) {
			return new Promise(resolve => {
				this.command('getDevices', forceUpdate, resolve);
			})
		},	
		
		storeDevicesSettings: function(data) {
			this.command('storeDevicesSettings', data);
		},

		testDevice: function(type, data) {
			this.command('testDevice', type, data);
		}
	}
});