PlatformElectron = Class.create(Platform, {
	available:	[
		'supports', 'playSound', 'openInBrowser', 'clearActions', 'addActions', 'displayReceipt',
		'storeInLocker', 'getFromLocker', 'connectionStatusUpdate', 'connectionStatusShow',
		'getApplicationSettings', 'storeApplicationSettings', 'getHardwareInformation', 'getAutoupdaterInformation', 'quitAndInstallUpdate',

		/* SalonhubPOS */
		'getDevices', 'storeDevicesSettings', 'getExternalDeviceCapabilities', 'testDevice'
	],

	initPlatform: function() {
		return new Promise(response => {

			/* Setup bridge */
			window.addEventListener('message', this.message.bindAsEventListener(this));

			/* Setup display broadcast channel */
			this.externalDisplay = new BroadcastChannel('external_display');

			/* Settings */
			this.api = Settings.api;

			/* Authenticate */
			this.command('initialize', function(data) {
				var account = data.account;
				var credentials = data.credentials;

				if (account.client && account.username && account.password && account.capabilities &&
					credentials.stage && credentials.actor)
				{
					this.account = {
						client:			account.client,
						username:		account.username,
						password:		MD5(account.password),
						code:			account.code,
						capabilities:	account.capabilities
					}

					this.credentials = {
						stage:			credentials.stage,
						actor:			credentials.actor,
						rights:			credentials.rights || 0
					};

					this.api = data.api;
					this.guid = data.guid;
					this.options = data.options;
					this.tokens = data.tokens;
					this.keys = data.keys;
					this.salons = account.salons;

					this.session = new Session({
						applicationToken:		this.tokens['Session.*'] || null,
						sessionToken:			this.tokens['Application.*'] || null,
					})
				}

				if (window.top != window) this.available.push('enableOverlay');
				if (window.top != window) this.available.push('disableOverlay');
				if (window.top != window) this.available.push('initializeWrapper');
				if (window.top != window) this.available.push('activateView');

				if (data.capabilities.paymentTransaction) this.available.push('paymentTransaction');
				if (data.capabilities.kickCashDrawer) this.available.push('kickCashDrawer');
				if (data.capabilities.displayLines) this.available.push('displayLines');
				if (data.capabilities.displayDefault) this.available.push('displayDefault');
				if (data.capabilities.printReceipt) this.available.push('printReceipt');

				Settings.location = data.options.location;
				Settings.autoPrintReceipt = data.options.autoPrintReceipt;

				response();
			});
		});
	},

	factory: function(id) {
		return this.apis[id].bind(this);
	},

	message: async function(event) {
		if (event.data.response) {
			if (this._callbacks[event.data.response]) {
				this._callbacks[event.data.response].apply(this, event.data.result);
			}
		}

		if (event.data.command) {
			switch (event.data.command) {
				case "lock":		Application.current._lock(); break;
				case "unlock":		Application.current._unlock(event.data.data[0]); break;
				case "event":		document.fire(event.data.data[0].event); break;
				case "callback":	let fn = this._callbacks[event.data.data[0].callback];
									let result;

									if (fn) {
										if (fn.constructor.name === "AsyncFunction") {
											result = await fn.apply(this);
										} else {
											result = fn.apply(this);
										}

										if (result) {
											if (event.data.callback) {
												event.source.postMessage({
													response: 	event.data.callback,
													result:		result
												}, '*');
											}
										}
									}

									break;
			}
		}
	},

	command: function() {
		if (arguments.length < 1) return;

		var command = arguments[0];
		var data = null;
		var callback = null;
		var id = null;

		if (typeof arguments[arguments.length - 1] == 'function') {
			callback = arguments[arguments.length - 1];
			data = Array.prototype.slice.call(arguments).slice(1,-1);
		} else {
			data = Array.prototype.slice.call(arguments).slice(1);
		}

		if (callback) {
			id = this.registerCallback(callback)
		}

		window.parent.postMessage({
			command:	command,
			data:		data,
			callback:	id
		}, '*');
	},

	registerCallback: function(callback) {
		if (typeof callback == 'function') {
			id = uniqueid();
			if (typeof this._callbacks == 'undefined') this._callbacks = {};
			this._callbacks[id] = callback;
			return id;
		}

		return callback;
	},

	apis: {
		supports: function(name, callback) {
			if (typeof callback != 'function') {
				return new Promise(resolve => {
					this.command('supports', name, resolve);
				})
			}

			this.command('supports', name, callback);
		},

		enableOverlay: function() {
			this.command('enableOverlay');
		},

		disableOverlay: function() {
			this.command('disableOverlay');
		},

		initializeWrapper: function(data, response) {
			this.command('initializeWrapper', data, response);
		},

		activateView: function(id, response) {
			this.command('activateView', id, response);
		},

		printReceipt: function(receipt, callback) {
			this.command('printReceipt', receipt, callback);
		},

		kickCashDrawer: function() {
			this.command('kickCashDrawer');
		},

		displayDefault: function() {
			this.command('displayDefault');
		},

		displayReceipt: function(receipt) {
			if (this.externalDisplay) {
				this.externalDisplay.postMessage({
					type:	'receipt',
					data:	receipt
				});
			}
		},

		displayLines: function(lineOne, lineTwo) {
			this.command('displayLines', lineOne, lineTwo);
		},

		paymentTransaction: function(receipt, callback) {
			this.command('paymentTransaction', receipt, callback);
		},

		storeInLocker: function(key, value) {
			this.command('storeInLocker', key, value);
		},

		getFromLocker: function(key) {
			return new Promise(resolve => {
				this.command('getFromLocker', key, resolve);
			});
		},

		playSound: function(name) {
			try {
				let sound = new Audio(_ROOT + "../assets/" + name + ".mp3");
				sound.play();
			} catch (e) {
			}
		},

		openInBrowser: function(url, options) {
			this.command('openInBrowser', url, options);
		},

		connectionStatusUpdate: function(state, method) {
			this.command('connectionStatusUpdate', state, method);
		},

		connectionStatusShow: function() {
			this.command('connectionStatusShow');
		},

		clearActions: function() {
			this.command('clearActions');
		},

		addActions: function(item) {
			if (item.type == 'action') {
				item.action = this.registerCallback(item.action);
			}

			this.command('addActions', item);
		},

		getHardwareInformation: function() {
			return new Promise(resolve => {
				this.command('getHardwareInformation', resolve);
			})
		},
		
		getAutoupdaterInformation: function(callback) {
			this.command('getAutoupdaterInformation', callback);
		},

		quitAndInstallUpdate: function() {
			this.command('quitAndInstallUpdate');
		},

		getExternalDeviceCapabilities: function(data) {
			return new Promise(resolve => {
				this.command('getExternalDeviceCapabilities', data, resolve);
			})
		},

		getDevices: function(forceUpdate) {
			return new Promise(resolve => {
				this.command('getDevices', forceUpdate, resolve);
			})
		},	
		
		storeDevicesSettings: function(data) {
			this.command('storeDevicesSettings', data);
		},

		testDevice: function(type, data) {
			this.command('testDevice', type, data);
		},

		getApplicationSettings: function() {
			return new Promise(resolve => {
				this.command('getApplicationSettings', resolve);
			})
		},	
		
		storeApplicationSettings: function(data) {
			this.command('storeApplicationSettings', data);
		},
	}
});