Action = Class.create();
Sheet = Class.create();

/* Prevent drag and drop redirection */

document.addEventListener('dragover', function(e) { e.preventDefault(); return false; }, false);
document.addEventListener('drop', function(e) { e.preventDefault(); return false; }, false);


/* Prevent navigation on backspace */

document.onkeydown = function (e) {
    if (e.keyCode == 8) {
        if (e.target.nodeName == 'BODY') {
            e.preventDefault();
        }
    }
};
