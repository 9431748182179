Database = {

    connection: null,
    upgraded: false,

    initialize: function(database) {
        Database.connection = database;

        setTimeout(() => {
            Database.cleanOldKeys('day')
        }, 5 * 1000);
    },

    create: function() {
        Database.connection.createObjectStore("day", { keyPath: "date" });
		Database.connection.createObjectStore("categories", { keyPath: "id" });
		Database.connection.createObjectStore("treatments", { keyPath: "id" });
		Database.connection.createObjectStore("products", { keyPath: "id" });
		Database.connection.createObjectStore("employees", { keyPath: "id" });
		Database.connection.createObjectStore("units", { keyPath: "id" });
		Database.connection.createObjectStore("groups", { keyPath: "id" });
		Database.connection.createObjectStore("discounts", { keyPath: "id" });
		Database.connection.createObjectStore("subscriptions", { keyPath: "id" });
		Database.connection.createObjectStore("filters", { keyPath: "id" });
		Database.connection.createObjectStore("suppliers", { keyPath: "id" });
		Database.connection.createObjectStore("salons", { keyPath: "id" });
		Database.connection.createObjectStore("terminals", { keyPath: "uuid" });
		Database.connection.createObjectStore("settings", { keyPath: "name" });
    },

    upgrade: function(from, to) {
        console.log('Database upgrade needed', from, to);

        if (from < 560 && to >= 560) {
            Database.connection.createObjectStore("terminals", { keyPath: "uuid" });
        }

        Database.upgraded = true;
    },

    store: async function(type, data) {
        if (Database.connection) {
            try {
                var transaction = Database.connection.transaction(type, "readwrite");
                var objectstore = transaction.objectStore(type);
                data.forEach(item => objectstore.put(item));
                
                if (type != 'day' && type != 'settings') {
                    await Database.cleanDeletedKeys(type, data);
                }
            } catch (e) {
            }
        }

        return data;
    },

    cleanOldKeys: async function(type) {
        let objectstore = Database.connection
            .transaction(type, "readwrite")
            .objectStore(type);

        let current = await new Promise(resolve => {
            let request = objectstore.getAllKeys();
            request.onsuccess = () => {
                resolve(request.result);
            };
        });

        let today = moment();

        current.forEach(key => {
            let age = moment(key).diff(today, 'days');
            if (age < -14) {
                objectstore.delete(key);
            }
        });
    },

    cleanDeletedKeys: async function(type, data) {
        let objectstore = Database.connection
            .transaction(type, "readwrite")
            .objectStore(type);

        let current = await new Promise(resolve => {
            let request = objectstore.getAllKeys();
            request.onsuccess = () => {
                resolve(request.result);
            };
        });

        let update = data.map(x => x[objectstore.keyPath]);
        let difference = current.filter(x => !update.includes(x));

        if (difference.length) {
            difference.forEach(key => {
                objectstore.delete(key);
            });
        }
    },

    retrieve: function(type, key) {
        return new Promise((resolve, reject) => {
            if (!Database.connection) {
                reject();
            }

            if (key) {
                Database.connection
                    .transaction(type)
                    .objectStore(type)
                    .get(key)
                    .onsuccess = function(event) {
                        resolve(event.target.result)
                    };
            }

            Database.connection
                .transaction(type)
                .objectStore(type)
                .getAll()
                .onsuccess = function(event) {
                    resolve(event.target.result)
                };
        });
    },
};