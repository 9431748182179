ApiLoader = Class.create();
ApiLoader.count = 0;
ApiLoader.prototype = {
	initialize: function(command, options) {
		this.uniqueid = this.buildIdentifier();

		command = command.split(':');
		this.method = command[0];
		this.url = command[1];
		this.retries = this.method == 'push' ? 3 : 1;

		this.options = Object.assign({
			format:		'json',
			visible:	true,
			parameters:	{}
		}, options || {});

		this.transport = null;
		this.tries = 0;

		if (this.options.visible) {
			this.timer = window.setTimeout(this.showStatus.bind(this), 2500);
		}

		if (Settings.debug) console.debug(this.uniqueid, 'Start', this.method, this.url, this.options.parameters);

		this.next();
	},
	
	buildIdentifier: function() {
		ApiLoader.count++;
		return MD5(new Date().getTime() + '_' + ApiLoader.count + '_' + Math.floor(Math.random()*100001));
	},

	next: function() {
		this.start = new Date().getTime();

		if (this.tries < this.retries) {
			this.tries++;

			this.load();
		} else {
			if (this.method == 'push') {
				if (this.timer) window.clearTimeout(this.timer);
				if (this.status) this.status.close();

				new Window.Confirm({
					message: 'Er is een fout opgetreden tijdens het opslaan',
					yes:	 { title: 'Annuleer' },
					no:		 { title: 'Opnieuw', color: 'green' },

					onClose: function(result) {
						if (!result) {
							this.tries = 1;

							if (this.options.visible) {
								this.timer = window.setTimeout(this.showStatus.bind(this), 2500);
							}

							this.load();
						}

						else {
							if (this.options.onFailure) {
								this.options.onFailure(this.transport.status);
							}

							this.onComplete();
						}
					}.bind(this)
				});

				return;
			}

			if (this.options.onFailure) {
				this.options.onFailure(this.transport.status);
			}

			this.onComplete();
		}
	},

	load: function() {
		var parameters = shallowClone(this.options.parameters);
		parameters._uniqueid = this.uniqueid;
		parameters._method = this.method;
		parameters._tries = this.tries;

		var options = {
			method:			this.method == 'retrieve' ? 'get' : 'post',
			parameters:		parameters,
			evalJSON: 		this.options.format == 'json' ? 'force' : (this.options.format == 'auto' ? true : false),

			onTimeout:		this.onTimeout.bindAsEventListener(this),
			onFailure:		this.onFailure.bindAsEventListener(this),
			onSuccess:		this.onSuccess.bindAsEventListener(this),
		}

		if (this.method == 'push') options.timeout = 10 * this.tries;

		if (Settings.debug) console.debug(this.uniqueid, 'Load', '#' + this.tries);

		new Ajax.Request(Application.api + this.url, options);
	},

	onTimeout: function(transport) {
		if (Settings.debug) console.debug(this.uniqueid, 'Timeout', '#' + this.tries);

		this.transport = transport;
		this.next();
	},

	onFailure: function(transport) {
		if (Settings.debug) console.debug(this.uniqueid, 'Failure', '#' + this.tries);

		this.transport = transport;
		this.next();
	},

	onSuccess: function(transport) {
		if (transport.status < 200 || transport.status >= 400) {
			this.onFailure(transport);
			return;
		}

		if (Settings.debug) console.debug(this.uniqueid, 'Success', '#' + this.tries, transport);

		if (this.options.onSuccess) {
			this.options.onSuccess(transport.responseJSON || transport.responseText);
		}

		this.onComplete();
	},

	onComplete: function(response) {
		if (Settings.debug) console.debug(this.uniqueid, 'Complete', (new Date().getTime() - this.start) + 'ms');

		window.clearTimeout(this.timer);

		if (this.options.visible) {
			this.hideStatus();
		}

		if (this.options.onComplete) {
			this.options.onComplete();
		}
	},

	showStatus: function() {
		if (Settings.debug) console.debug(this.uniqueid, 'Show status');
		this.status = new Window.Status(this.method == 'retrieve' ? 'Laden...' : 'Opslaan...');
	},

	hideStatus: function() {
		if (this.status) {
			if (Settings.debug) console.debug(this.uniqueid, 'Hide status');

			this.status.close();
		}
	}
}



SafeApiLoader = Class.create();
SafeApiLoader.prototype = {
	initialize: function(url, options) {
		this.url = url;
		this.options = options;

		this.timeout = 4;
		this.retries = 2;

		this.reset();
	},

	reset: function() {
		this.urls = [];
		for (var i = 0; i < Settings.apis.length; i++) {
			this.urls.push(Settings.apis[i] + this.url);
		}

		this.response = null;

		this.next();
	},

	next: function() {
		if (Settings.debug) console.debug('SafeApiLoader', 'next');

 		var url = this.urls.shift();

		if (url) {
			this.load(url);
		} else {
			this.failed();
		}
	},

	load: function(url) {
		var options = shallowClone(this.options);
		options.timeout = this.timeout;
		options.onFailure = this.onFailure.bindAsEventListener(this);
		options.onTimeout = this.onFailure.bindAsEventListener(this);
		options.onSuccess = this.onSuccess.bindAsEventListener(this);
		options.onComplete = this.onComplete.bindAsEventListener(this);

		if (Settings.debug) console.debug('SafeApiLoader', 'open', url);

		new Ajax.Request(url, options);
	},

	onFailure: function(response) {
		if (Settings.debug) console.debug('SafeApiLoader', 'failure', response);

		this.response = response;
		this.next();
	},

	onSuccess: function(response) {
		if (Settings.debug) console.debug('SafeApiLoader', 'success', response);

		if (this.options.onSuccess) {
			this.options.onSuccess(response);
		}

		if (this.options.onComplete) {
			this.options.onComplete(response);
		}
	},

	onComplete: function(response) {
	},

	failed: function() {
		if (Settings.debug) console.debug('SafeApiLoader', 'first try failed...');
		var retry = true;

		/* No database connection, or no authorisation */
		if (this.response.status > 0) {
			retry = false;
		}

		if (retry && this.retries > 0) {
			this.retries--;
			this.timeout += 4;

			if (Settings.debug) console.debug('SafeApiLoader', 'retry...');

			this.reset();
			return;
		} else {
			if (Settings.debug) console.debug('SafeApiLoader', 'failing permanently...');
		}

		if (this.options.onFailure) {
			this.options.onFailure(this.response);
		}

		if (this.options.onComplete) {
			this.options.onComplete(this.response);
		}
	}
};