System = {
	Type: {},

	Engine: {
		Blink:			navigator.userAgent.indexOf(' Chrome/') !== -1,
		Edge:			navigator.userAgent.indexOf(' Edge/') !== -1,
		WebKit:			navigator.userAgent.indexOf(' AppleWebKit/') !== -1,
		Trident:		navigator.userAgent.indexOf(' Trident/') !== -1,
		Gecko:			navigator.userAgent.indexOf(' Gecko/') !== -1,
	},

	OS: {
		Android:		(/android/gi).test(navigator.userAgent),
		Chromebook:		(/CrOS/g).test(navigator.userAgent),
		iOS:			(/iphone|ipod|ipad/gi).test(navigator.userAgent),
		Mac:			!(/iphone|ipod|ipad/gi).test(navigator.userAgent) && (/mac/gi).test(navigator.userAgent),
		Windows:		(/windows/gi).test(navigator.userAgent),
		WebOS:			(/Web0S/g).test(navigator.userAgent) || (/Colt\//g).test(navigator.userAgent)
	},

	Runtime: {
		Electron:		navigator.userAgent.indexOf(' Electron') !== -1,
		Capacitor:		navigator.userAgent.indexOf(' Capacitor') !== -1
	},

	Features: {
		Touch:			!! navigator.maxTouchPoints || !! ('ontouchstart' in window),
		Retina: 		window.devicePixelRatio > 1,
		Standalone:		false
	}
};

if (System.Engine.Blink && System.Engine.Edge) {
	System.Engine.Blink = false;
}

if (System.Engine.Blink && System.Engine.WebKit) {
	System.Engine.WebKit = false;
}

if (System.OS.Mac && navigator.maxTouchPoints > 0) {
	System.OS.Mac = false;
	System.OS.iOS = true;
}

System.Type.Mobile = System.OS.iOS || System.OS.Android;
System.Type.Desktop = !System.Type.Mobile;
System.Type.Tablet = System.Runtime.Capacitor || (/ipad/gi).test(navigator.userAgent) || (System.OS.iOS && (/Intel Mac/g).test(navigator.userAgent)) || (System.OS.Android && !(/mobile/gi).test(navigator.userAgent));
System.Runtime.Browser = ! System.Runtime.Electron && ! System.Runtime.Capacitor;
System.Runtime.Application = System.Runtime.Electron || System.Runtime.Capacitor;
System.Features.Scroll = (System.Engine.Blink || System.Engine.WebKit) && !System.Type.Mobile;
System.Features.Printing = location.pathname.endsWith("/report.html");

System.Features.ProgressiveWebApp = !System.Features.Printing && /source=pwa/.test((window.parent ? window.parent.location : location).href);
System.Features.TrustedWebActivity = !System.Features.Printing && /source=twa/.test((window.parent ? window.parent.location : location).href);

System.Features.Standalone =
	System.Features.ProgressiveWebApp ||
	window.navigator.standalone === true ||
	window.matchMedia('(display-mode: standalone)').matches;
