ViewController = Class.create();
ViewController.prototype = {
	initialize: async function(application, options) {
		this.application = application;
		this.items = options.items;
		this.options = options;
		this.current = this.options.current;
		this.path = [];

		let parameters = new URLSearchParams(window.location.search);

		if (parameters.has('path')) {
			this.path = parameters.get('path').split('/');

			if (this.path.length) {
				this.current = this.determineCurrent(this.path[0]);
			}
		}

		this.loaded = false;

		if (Settings.debug) console.debug('ViewController', 'initialize', this.current);

		this.build();

		await requireAsync(options.deps);

		try {
			await this.call(options.initialize ? options.initialize.bind(this) : false);
			await this.load(options.load || false);

			this.run(this.current);
			this.loaded = true;
		}
		catch(e) {
			this.failure(e);
		}
	},

	call: function(f) {
        return new Promise((resolve, reject) => {
			if (!f) {
				resolve();
				return;				
			}

			f(resolve);
		});
	},

	load: function(options) {
        return new Promise((resolve, reject) => {
			if (!options) {
				resolve();
				return;				
			}

			/* Load settings from the server*/
			new Ajax.Request(options.url, {
				method: 'get',
				evalJSON: 'force',
				onSuccess: function(transport) {
					if (transport.status == 0) {
						reject(_CONNECTION_OFFLINE);
						return;
					}

					if (transport.responseJSON && options.handle) {
						options.handle(transport.responseJSON, resolve);
						return;
					}

					resolve();
				}.bind(this),

				onFailure: function(transport) {
					switch(transport.status) {
						case 401:		reject(_CONNECTION_FAILED); break;
						case 402:		reject(_CONNECTION_FAILED); break;
						case 404:		reject(_CONNECTION_UPGRADE); break;
						case 410:		reject(_CONNECTION_UPGRADE); break;
						case 503:		reject(_CONNECTION_UNAVAILABLE); break;
						default:		reject(_CONNECTION_UNKNOWN); break;
					}

					this.failure();
				}.bind(this)
			});
		});
	},

	failure: function(state) {
		Application.connectionOffline({
			state: 	state,
			reload:	true
		});
	},

	determineCurrent: function(id) {
		var enabled = false;
		
		if (id) {
			for (var i = 0; i < this.items.length; i++) {
				if (this.items[i].id == id) {
					enabled = (typeof this.items[i].enabled == 'undefined' || this.items[i].enabled);
				}
			}
		}

		return enabled ? id : this.options.current
	},

	run: function(id, action) {
		var previous, item;

		if (Application.clearActions) {
			Application.clearActions();
		}

		for (var i = 0; i < this.items.length; i++) {
			if (this.items[i].id == id) {
				item = i;
			}

			if (this.items[i].id == this.current) {
				previous = i;
			}
		}

		this.current = id;

		if (this.items[previous].lazy && this.items[previous].lazy.indexOf(this.items[item].id) !== -1) {
			action = false;
		}

		if (this.items[item].action && action) {
			this.items[item].action();
		}

		else {
			for (var i = 0; i < this.items.length; i++) {
				if (this.items[i].controller) {
					this.items[i].controller.hide();
				}
			}

			if (typeof this.items[item].controller != 'undefined') {
				if (this.items[item].controller) {
					this.items[item].controller.show();
				}
			}

			else {
				this.init(this.items[item].id, true);
			}
		}
	},

	init: async function(id, lazy) {
		var item;

		for (var i = 0; i < this.items.length; i++) {
			if (this.items[i].id == id) {
				item = i;
			}
		}

		if (typeof this.items[item].enabled != 'undefined' && !this.items[item].enabled) {
			return;
		}

		if (typeof this.items[item].initialized != 'undefined' && this.items[item].initialized) {
			return;
		}

		this.items[item].parent = this;
		this.items[item].path = this.path.slice(1);
		this.items[item].initialized = true;
		this.items[item].controller = null;

		if (this.items[item].deps) {
			await requireAsync(this.items[item].deps);
		}

		if (this.items[item].initialize) {
			this.items[item].controller = this.items[item].initialize.bind(this)(this.items[item]);
		}

		if (this.items[item].lazy && lazy) {
			for (var i = 0; i < this.items[item].lazy.length; i++) {
				window.setTimeout(function(l) { this.init.bind(this)(l) }.bind(this, this.items[item].lazy[i]), 1000 * i);
			}
		}
	},

	activate: function(id) {
		if (Application.activateView) {
			Application.activateView(id);
		}

		this.run(id, true);
	},

	build: function() {
		if (Application.initializeWrapper) {
			var tabs = [];

			for (var i = 0; i < this.items.length; i++) {
				let required = false;

				if (!this.items[i].required) required = true;
				if (Settings.rights & this.items[i].required) required = true;
				if (typeof this.items[i].required === 'function' && this.items[i].required()) required = true;

				if (required && (typeof this.items[i].visible == 'undefined' || this.items[i].visible)) {
					tabs.push({
						id: 		this.items[i].id,
						name: 		this.items[i].name,
						icon: 		this.items[i].icon,
						enabled:	(typeof this.items[i].enabled == 'undefined' || this.items[i].enabled)
					})
				}
			}

			var lock = null;
			if (!(Settings.rights & _USER_ADMIN_ || Settings.rights & _USER_VIEW_ADMIN_ || this.application.platform.account.code == '')) {
				lock = {
					locked:		Application.locked,
					code:		this.application.platform.account.code
				}
			}

			Application.initializeWrapper(
				{
					title:		this.application.platform.title,

					tabs:		{
									items: 		tabs,
									current:	this.current
								},

					lock:		lock
				},

				id => {
					document.fire('application:navigation');

					if (this.loaded) {
						this.run(id, true);
					}
				}
			);

			return;
		}
	},


	set loading(value) {
		if (value) {
			document.body.classList.add('loading');
			return;
		}

		document.body.classList.remove('loading');
	}
};
