var _CONNECTION_SUCCESS = 1;
var _CONNECTION_FAILED = 0;
var _CONNECTION_UNAVAILABLE = -1;
var _CONNECTION_UPGRADE = -2;
var _CONNECTION_UNKNOWN = -3;
var _CONNECTION_OFFLINE = -4;

var _USER_NONE_ = 0;
var _USER_VIEW_AGENDA_ = 1;
var _USER_VIEW_REPORTS_ = 2;
var _USER_VIEW_STOCK_ = 4;
var _USER_VIEW_REGISTER_ = 8;
var _USER_VIEW_DASHBOARD_ = 32;
var _USER_VIEW_ADMIN_ = 64;
var _USER_VIEW_CAMPAIGNS_ = 1024;
var _USER_SYNC_ = 128;
var _USER_ADMIN_ = 256;
var _USER_IMPORT_ = 512;
var _USER_DEVELOPER_ = 1024;

var _EMPLOYEE_GENDER_MALE_ = 0;
var _EMPLOYEE_GENDER_FEMALE_ = 1;

var _EMPLOYEE_TYPE_REGULAR_ = 0;
var _EMPLOYEE_TYPE_STUDENT_ = 1;
var _EMPLOYEE_TYPE_OWNER_ = 2;
var _EMPLOYEE_TYPE_INTERN_ = 3;
var _EMPLOYEE_TYPE_RENTER_ = 4;
var _EMPLOYEE_TYPE_PAYROLL_ = 5;
var _EMPLOYEE_TYPE_OTHER_ = 6;

var _CUSTOMER_GENDER_MALE_ = 0;
var _CUSTOMER_GENDER_FEMALE_ = 1;
var _CUSTOMER_GENDER_CHILD_ = 2;
var _CUSTOMER_GENDER_FAMILY_ = 3;

var _TREATMENT_TYPE_MALE_ = 1;
var _TREATMENT_TYPE_FEMALE_ = 0;
var _TREATMENT_TYPE_CHILD_ = 2;

var _TREATMENT_PART_TYPE_REGULAR_ = 0;
var _TREATMENT_PART_TYPE_STUDENT_ = 1;
var _TREATMENT_PART_TYPE_EMPTY_ = 2;

var _DISCOUNT_TYPE_NONE_ = 0;
var _DISCOUNT_TYPE_PERCENTAGE_ = 1;
var _DISCOUNT_TYPE_REGULAR_ = 2;
var _DISCOUNT_TYPE_FIXED_ = 3;
var _DISCOUNT_TYPE_BONUS_ = 4;
var _DISCOUNT_TYPE_SUBSCRIPTION_ = 5;

var _POINTS_TYPE_NONE_ = 0;
var _POINTS_TYPE_MAXIMUM_ = 1;
var _POINTS_TYPE_MANUAL_ = 2;

var _BONUS_TYPE_NONE_ = 0;
var _BONUS_TYPE_MAXIMUM_ = 1;
var _BONUS_TYPE_MANUAL_ = 2;

var _TAX_0_ = -1;
var _TAX_6_ = 0;
var _TAX_19_ = 1;
var _TAX_21_ = 2;
var _TAX_9_ = 3;

var _MUTATION_TYPE_TREATMENT_ = 0;
var _MUTATION_TYPE_PRODUCT_ = 1;
var _MUTATION_TYPE_EXPENSE_ = 2;
var _MUTATION_TYPE_COUPON_ = 3;
var _MUTATION_TYPE_SUBSCRIPTION_ = 4;
var _MUTATION_TYPE_DEPOSIT_ = 5;
var _MUTATION_TYPE_PAYMENT_ = 16;

var _EXPENSE_TYPE_OTHER_ = 0;
var _EXPENSE_TYPE_PRIVATE_WITHDRAWAL_ = 1;
var _EXPENSE_TYPE_EMPLOYEE_MEALS_ = 2;
var _EXPENSE_TYPE_EMPLOYEE_TRAVEL_ = 3;
var _EXPENSE_TYPE_EMPLOYEE_PARKING_ = 4;
var _EXPENSE_TYPE_EMPLOYEE_OTHER_ = 5;
var _EXPENSE_TYPE_CLEANING_ = 6;
var _EXPENSE_TYPE_AMENITIES_ = 7;
var _EXPENSE_TYPE_READING_ = 8;
var _EXPENSE_TYPE_OFFICE_ = 9;
var _EXPENSE_TYPE_STAMPS_ = 10;
var _EXPENSE_TYPE_INTERIOR_ = 11;
var _EXPENSE_TYPE_WITHDRAWAL_ = 12;
var _EXPENSE_TYPE_ADVERTISING_ = 13;

var _PAYMENT_NONE_ = -1;
var _PAYMENT_CASH_ = 0;
var _PAYMENT_DEBITCARD_ = 1;
var _PAYMENT_DELAYED_ = 2;
var _PAYMENT_INVOICE_ = 3;
var _PAYMENT_BANK_ = 4;
var _PAYMENT_CREDITCARD_ = 5;
var _PAYMENT_OTHER_ = 6;
var _PAYMENT_VVV_ = 7;
var _PAYMENT_REQUEST_ = 8;
var _PAYMENT_MUTATION_ = 16;
var _PAYMENT_WRITEOFF_ = 32;

var _PRICECHANGE_NONE_ = 0;
var _PRICECHANGE_RAISE_ = 1;
var _PRICECHANGE_LOWER_ = 2;
var _PRICECHANGE_FIXED_ = 3;

var _APPOINTMENT_STATE_NEW_ = 0;
var _APPOINTMENT_STATE_STARTED_ = 1;
var _APPOINTMENT_STATE_PAYED_ = 2;
var _APPOINTMENT_STATE_NOSHOW_ = 3;

var _CATEGORY_TYPE_TREATMENTS = 0;
var _CATEGORY_TYPE_PRODUCTS = 1;

var _DISCOUNT_METHOD_NONE = 0;
var _DISCOUNT_METHOD_DATE = 1;
var _DISCOUNT_METHOD_TYPE = 2;

var _DISCOUNT_MUTATION_ALL = -1;
var _DISCOUNT_MUTATION_TREATMENTS = 0;
var _DISCOUNT_MUTATION_PRODUCTS = 1;

var _RECEIPT_TYPE_SALES = 0;
var _RECEIPT_TYPE_REGISTER = 1;
var _RECEIPT_TYPE_OTHER = 2;

var _RECEIPT_STATE_CLEAN = 0;
var _RECEIPT_STATE_DIRTY = 1;
var _RECEIPT_STATE_FINAL = 2;
var _RECEIPT_STATE_PROCESS = 3;
var _RECEIPT_STATE_ERROR = 4;
var _RECEIPT_STATE_DONE = 5;

var _STOCK_STATUS_STARTED_ = 0;
var _STOCK_STATUS_BACKORDERED_ = 1;
var _STOCK_STATUS_DELIVERED_ = 2;
var _STOCK_STATUS_SEND_ = 3;
var _STOCK_STATUS_RECEIVED_ = 4;
var _STOCK_STATUS_CONFIRMED_ = 5;
var _STOCK_STATUS_FAILED_ = 6;



var _PAYMENT_STATUS_SUCCESS = 1;
var _PAYMENT_STATUS_FAILED = 0;
var _PAYMENT_STATUS_UNKNOWN = -1;

var _PAYMENT_SUCCESS = 1;

var _PAYMENT_POS_INVALIDREQUEST = -1;
var _PAYMENT_POS_NOTCONNECTED = -2;
var _PAYMENT_POS_TIMEOUT = -3;
var _PAYMENT_POS_TERMINAL_UNKOWNERROR = -4;
var _PAYMENT_POS_TERMINAL_NOTCONNECTED = -5;
var _PAYMENT_POS_TERMINAL_TIMEOUT = -6;
var _PAYMENT_POS_TERMINAL_FAILED = -7;

var _PAYMENT_PAY_RESUME_FAILED = -8;
var _PAYMENT_PAY_CONNECTION_FAILED = -9;
var _PAYMENT_PAY_TERMINAL_UNKNOWN = -11;
var _PAYMENT_PAY_BAD_API_KEY = -12;
var _PAYMENT_PAY_NETWORK_FAILED = -13;
var _PAYMENT_PAY_NETWORK_UNKNOWN = -14;
var _PAYMENT_PAY_SERVER_ERROR = -15;
var _PAYMENT_PAY_REJECTED = -16;
var _PAYMENT_PAY_INTERVENTION = -17;
var _PAYMENT_PAY_CANCELLED = -18;


var _PAYMENT_ERROR_UNKNOWN = -1;
var _PAYMENT_ERROR_NONE = 0;
var _PAYMENT_ERROR_NOTOK = 1;
var _PAYMENT_ERROR_VALUTAWRONG = 2;
var _PAYMENT_ERROR_PRODUCTINFOWRONG = 3;
var _PAYMENT_ERROR_PRICELITRERATECHECKFAILED = 4;
var _PAYMENT_ERROR_TOTALNOTEQUAL = 5;
var _PAYMENT_ERROR_SYNTAXERROR = 6;
var _PAYMENT_ERROR_AMOUNTNOTALLOWED = 7;
var _PAYMENT_ERROR_AMOUNTTOOHIGH = 8;
var _PAYMENT_ERROR_INVALIDMESSAGEVERSIONNUMBER = 9;
var _PAYMENT_ERROR_TRXSTILLBUSY = 10;
