class Session {

    constructor(options) {
        this.options = options;

        if (!this.options.applicationToken) {
            this.sessionToken = new Token({
                token:  this.options.sessionToken,
            });

            return;
        }

        this.applicationToken = new Token({
            token:  this.options.applicationToken,

            onAfterExpire: () => {
                new Window.Alert({
                    message: 		'Deze support sessie is verlopen',
                    explaination: 	'Ga naar de beheer omgeving en open een nieuwe support sessie voor deze klant.',
                    button:			{ title: 'OK', color: 'red' },
                    onClose:		() => {
                        Application.close();
                    }
                });
            }
        });

        let storedSessionToken = sessionStorage.getItem('sessionToken-' + this.applicationToken.value);

        this.sessionToken = new Token({
            token:  storedSessionToken || this.options.sessionToken,

            onBeforeExpire: () => {
                this.requestNewToken();
            },

            onAfterExpire: () => {
                this.requestNewToken();
            }
        });

        document.addEventListener("visibilitychange", () => {
            if (!document.hidden) {
                this.checkToken();
            }
        });

        window.addEventListener("online", () => {
            this.checkToken();
        });
    }
    
    async checkToken() {
        if (!this.sessionToken.valid) {
            await this.requestNewToken();
            return;
        }
    }

    async requestNewToken() {
        let credentials = `token=${this.applicationToken.value}`;
        let url = `${Application.current.platform.api}api/=${base32.stringify(credentials)}/Session.Token/refresh`;

        if (url) {
            try {
                let response = await fetch(url); 
                let result = await response.json();

                if (result.tokens['Application.*']) {
                    this.sessionToken.update(result.tokens['Application.*']);
                    sessionStorage.setItem('sessionToken-' + this.applicationToken.value, result.tokens['Application.*']);
                }
            }
            catch(e) {
            }
        }
    }

    async requestTemporaryToken(client) {
        let credentials = `token=${this.applicationToken.value}&client=${client}`;
        let url = `${Application.current.platform.api}api/=${base32.stringify(credentials)}/Session.User/authorize`;

        if (url) {
            try {
                let response = await fetch(url); 
                let result = await response.json();

                if (result.tokens['Session.*']) {
                    return result.tokens['Session.*'];
                }
            } catch(e) {
            }
        }
    }

    async getToken() {
        if (this.applicationToken) {
            await this.checkToken();
        }
        
        return this.sessionToken.value;
    }
}