Telemetry = {};

Telemetry.setup = function() {

	/* Prototype AJAX exception handler */

	if (Ajax) {
		Ajax.Responders.register({ 
			onException: function(request, error) { 
				console.error(error.name + ': ' + error.message, error);

				if (request.transport.status != 200) {
					return;
				}

				Telemetry.send({
					message:	error.name + ': ' + error.message, 
					error:		error,
					payload:	{
						silent:		true,
						request: 	{
							url:	request.url,
							status:	request.transport.status
						}
					}
				});
			} 
		});
	}


	/* Regular error handler */

	let errorVisible = false;

	window.onerror = function(message, url, line, column, error) {
		if (message == 'ResizeObserver loop limit exceeded') {
			console.log('Ignoring ' + message);
			return;
		}

		Telemetry.send({
			message:	message, 
			error:		error,
			payload:	{
				silent:		errorVisible,
				original:	{
					fileName: url, 
					lineNumber: line, 
					columnNumber: column
				}
			}
		});

		if (errorVisible == false) {
			errorVisible = true;

			if (Window.Alert) {
				new Window.Alert({
					'message':			'Er is een fout opgetreden',
					'explaination':		'Deze melding is automatisch doorgestuurd naar Salonhub. Mocht u deze melding vaker krijgen, neem dan a.u.b. contact op.',
					'onClose':			() => errorVisible = false
				})
			} else {
				alert('Er is een fout opgetreden\n\nDeze melding is automatisch doorgestuurd naar Salonhub. Mocht u deze melding vaker krijgen, neem dan a.u.b. contact op.');
				errorVisible = false;
			}
		}
	};
};

Telemetry.error = function() {
	setTimeout(() => {
		errror();
	}, 300);
};

Telemetry.log = function(event) {
	console.log(event.message, event.details);

	let guid = '';

	let data = new FormData;
	data.set('type', event.type);
	data.set('message', event.message);
	data.set('details', JSON.stringify(event.details));
	data.set('version', Settings.version);
	data.set('guid', Telemetry.guid());

	navigator.sendBeacon('https://master.salonhub.nl/' + Settings.revision + '/telemetryEvent', data)
}

Telemetry.send = function(telemetry) {
	requirejs(['core/lib/stacktrace'], StackTrace => {

		/* Collect current client and salon */

		let client = Settings.client ? Settings.client : '';
		let salon = Settings.salon ? Settings.salon : '';

		if (typeof Runtime != 'undefined') {
			let account = Runtime.Controller.current.account || Runtime.Controller.current.settings.account;

			if (account) {
				client = account.client;
			}
		}

		/* Use StackTrace to build a trace from an Error */

		StackTrace = (StackTrace || window.StackTrace || module.exports);

		(async () => {
			if (StackTrace && StackTrace.fromError) {
				let trace;

				if (telemetry.error) {
					trace = await StackTrace.fromError(telemetry.error);
				}

				/* Check if we have an invalid trace */

				if (!trace || trace[0].fileName == trace[0].source) {
					let original = {};

					if (telemetry.payload && telemetry.payload.original) {
						original = telemetry.payload.original;
					}

					trace = [
						{
							fileName: telemetry.payload.original.fileName || '',
							lineNumber: telemetry.payload.original.lineNumber || 0,
							columnNumber: telemetry.payload.original.columnNumber || 0,
						}
					];
				}

				let data = new FormData;
				data.set('client', client);
				data.set('actor', salon);
				data.set('message', telemetry.message);
				data.set('url', trace[0].fileName);
				data.set('line', trace[0].lineNumber);
				data.set('column', trace[0].columnNumber);
				data.set('details', JSON.stringify(Object.assign(telemetry.payload, { trace })));
				data.set('version', Settings.version);
				data.set('guid', Telemetry.guid());

				navigator.sendBeacon('https://master.salonhub.nl/' + Settings.revision + '/telemetryError', data)
			}
		})()
	});
}

Telemetry.guid = function() {
	try {
		if (typeof Runtime != 'undefined') {
			return Runtime.Controller.current.settings.guid;
		}
		else {
			return Application.current.platform.guid;
		}
	} catch(e) {
	}

	return '';
}