
class Pubsub {

    constructor() {
        this.platform = Application.current.platform;
        this.listeners = {};

        this._method = 'offline';
        this._state = 'initializing';
        this._away = false;
        this._ping = null;
        this._attempts = 0;
        this._timestamp = moment().unix();

        window.addEventListener('online', () => {

            /* Wait a bit until the network is fully operational */
            setTimeout(() => {
                this.check();
            }, 1000);
		});

        window.addEventListener('offline', () => {
            this.method = 'offline';
		});

        window.addEventListener('pageshow', () => {
            this._away = false;
            this.check();
        });

        window.addEventListener('pagehide', () => {
            this._away = true;
        });

        window.addEventListener('beforeunload', () => {
            this._away = true;
        });

        setInterval(() => {
            this.check();
        }, 10 * 1000);
    }

    on(event, callback) {
        this.listeners[event] = callback;
    }

	async connect() {
        this._state = 'starting';

        let token = await this.platform.session.getToken();
		let credentials = `token=${token}&salon=${this.platform.credentials.actor}`;
		let url = (this.platform.api + 'socket/').replace('https:', 'wss:') + '=' + base32.stringify(credentials);

		this.socket = new WebSocket(url);

		this.socket.addEventListener('open', (event) => {
            this._ping = event.timeStamp;
            this._state = 'connecting';
            this._attempts = 0;
        });

		this.socket.addEventListener('message', (event) => {
            if (event.target !== this.socket) {
                return;
            }

            let data = JSON.parse(event.data);

            if (data.command == 'message') {
                this.method = 'streaming';
                this._timestamp = data.timestamp;                

                if (this.listeners['message']) {
                    this.listeners['message'](data.message);
                }
            }

            if (data.command == 'hello') {
                this._state = 'connected';
                this.method = 'streaming';

                if (this._timestamp < moment().unix()) {
                    if (this.listeners['backlog']) {
                        this.listeners['backlog'](this._timestamp, false);
                    }
                } 
                
                this._timestamp = moment().unix();
    
                if (this.listeners['connect']) {
                    this.listeners['connect']();
                }
            }

            if (data.command == 'ping') {
                if (data.connected) {
                    this.method = 'streaming';
                    this._timestamp = data.timestamp;
                }
                else {
                    this.method = 'polling';
                }

                this._ping = event.timeStamp;
            }
		});

        this.socket.addEventListener('error', (event) => {
            if (event.target !== this.socket) {
                return;
            }

            if (this.listeners['error']) {
                this.listeners['error'](event);
            }
        });

		this.socket.addEventListener('close', (event) => {
            if (event.target !== this.socket) {
                return;
            }

            if (this._state == 'starting') {
                this._state = 'disconnected';
                this._attempts++;
                return;
            }

            let reason;

            switch (event.code) {
                case 1000:  reason = 'Normal closure'; break;
                case 1001:  reason = 'Going away'; break;
                case 1002:  reason = 'Protocol error'; break;
                case 1003:  reason = 'Unsupported Data'; break;
                case 1005:  reason = 'No Status Rcvd'; break;
                case 1006:  reason = 'Abnormal closure'; break;
                case 1007:  reason = 'Invalid frame payload data'; break;
                case 1008:  reason = 'Policy Violation'; break;
                case 1009:  reason = 'Message Too Big'; break;
                case 1010:  reason = 'Mandatory Ext.'; break;
                case 1011:  reason = 'Internal Error'; break;
                case 1012:  reason = 'Service Restart'; break;
                case 1013:  reason = 'Try Again Later'; break;
                case 1014:  reason = 'Bad Gateway'; break;
                case 1015:  reason = 'TLS handshake'; break;
                default:    reason = 'Unknown closure'; break;
            }

            this._state = 'disconnected';

            if (this.listeners['disconnect']) {
                this.listeners['disconnect']({
                    code:   event.code,
                    reason: reason,
                });
            }
        });
	}

    async check() {
        if (this._state == 'connecting' || this._state == 'connected') {

            /* Check if our connection has stalled */

            let ping = Math.round((performance.now() - this._ping) / 1000);

            if (ping >= 20) {
                console.log('time since last ping: ' + ping + 's');
            }

            if (ping >= 60) {
                console.log('connection is stalled');
                
                if (this.socket) {
                    this.socket.close();
                }
                
                if (this.listeners['disconnect']) {
                    this.listeners['disconnect']({
                        code:   null,
                        reason: 'Connection has gone stale',
                    });
                }

                this._state = 'disconnected';
            }

            /* Check if the upstream connection of the server has been lost */

            if (moment().unix() - this._timestamp >= 60) {
                console.log('upstream connection has been lost, time to fetch the backlog...');

                if (this.listeners['backlog']) {
                    let result = await this.listeners['backlog'](this._timestamp, true);

                    if (result) {
                        this._timestamp = moment().unix();
                        this.method = 'polling';
                    }
                }
            }

            return;
        }

        if (this._state == 'starting') {
            return;
        }

        if (this._away) {
            return;
        }

        if (!navigator.onLine) {
            return;
        }

        if (this._state == 'disconnected') {

            /* Back off the server when we have a lot of failed attempts */

            if (this._attempts > 4 && this._attempts <= 8 && this._attempts % 2 != 0) {
                this._attempts++;
                return;
            }

            if (this._attempts > 8 && this._attempts <= 16 && this._attempts % 3 != 2) {
                this._attempts++;
                return;
            }

            if (this._attempts > 16 && this._attempts % 4 != 2) {
                this._attempts++;
                return;
            }


            /* Fetch the backlog when we've been disconnected for a while */

            if (this._attempts == 1 || moment().unix() - this._timestamp >= 60) {
                if (this.listeners['backlog']) {
                    let result = await this.listeners['backlog'](this._timestamp, true);

                    if (result) {
                        this._timestamp = moment().unix();
                        this.method = 'polling';
                    } else {
                        this.method = 'offline';
                    }
                }
            }
            
            /* Try to reconnect */

            this.connect();
        }
    }

    set method(value) {
        let update = false;

        if (this._method != value) {
            update = true;
        }

        this._method = value;

        if (update) {
            if (this.listeners['method']) {
                this.listeners['method'](value);
            }
        }
    }

    get method() {
        return this._method;
    }
}