

let Api = {

    endpoint: async function(api) {
        let platform = Application.current.platform;

        let url;

        /* URL */
        if (api.indexOf('http') !== -1) {
            url = api;
        }

        else {
            /* Determine credentials */

            let credentials = '';
            let token = await platform.session.getToken();

            if (token) {
                credentials += `token=${token}`;
            }
            else {
                if (platform.account.client) {
                    credentials += `client=${platform.account.client}`;
                }

                if (platform.credentials.actor) {
                    credentials += `&actor=${platform.credentials.actor}`;
                }

                if (platform.account.username) {
                    credentials += `&user=${platform.account.username}&password=${platform.account.password}`;
                }
            }


            /* Mapped */
            if (api.indexOf('/') === -1) {
                // url = Application.api + api;

                /* Build URL */

                if (platform.credentials.actor) {
                    let actor = '*';

                    if (['dashboard', 'import', 'main', 'settings'].includes(platform.credentials.actor)) {
                        actor = platform.credentials.actor;
                    }

                    url = `${platform.api}api/=${base32.stringify(credentials)}/${platform.credentials.stage}/${actor}/${api}`;
                }
                else {
                    url = `${platform.api}api/=${base32.stringify(credentials)}/${platform.credentials.stage}/${api}`;
                }
            }


            /* Direct */
            else {

                /* Determine view and method */

                let parts = api.split('/');
                let view = parts[0];
                let method = parts[1];

                /* Build URL */

                url = `${platform.api}api/=${base32.stringify(credentials)}/${view}/${method}`;
            }
        }

        return url;
    },

    Request: async function(api, options, data) {
        let url = await Api.endpoint(api);
        let legacy = Object.hasOwnProperty(options, 'evalJSON');
        
        options = Object.assign({
            method: 	'get',
            evalJSON: 	'force',
        }, options || {});

        if (data) {
            options.parameters = data;
        }

        /* Automatically encode objects as JSON */

        if (options.parameters && typeof options.parameters === 'object') {
            for (const property in options.parameters) {
                if (typeof options.parameters[property] === 'object') {
                    options.parameters[property] = JSON.stringify(options.parameters[property]);
                }
            }
        }

        /* Automatically parse JSON in the response */

        if (!legacy && options.onSuccess) {
            let onSuccess = options.onSuccess;

            options.onSuccess = function(transport) {
                if (transport.responseJSON) {
                    onSuccess(transport.responseJSON);
                }
            };
        }

        new Ajax.Request(url, options);
    },


    Fetch: async function(api, options, data) {
        options = options || {};

        return new Promise(resolve => {
            options.onSuccess = function(data) {
                resolve(data);
            };

            Api.Request(api, options, data);		
        });
    }
}