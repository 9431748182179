PlatformPrinting = Class.create(Platform, {
	available:	[],

	initPlatform: function() {
		return new Promise(response => {

			/* Settings */
			this.api = Settings.api;

			/* Authenticate */

			this.account = {
				client:			Application.current.options.client,
				username:		Application.current.options.username,
				password:		Application.current.options.password,
				code:			"",
				capabilities:	{ reports: true },
			}

			this.credentials = {
				stage:			Application.current.options.stage,
				actor:			Application.current.options.actor,
				rights:			_USER_VIEW_REPORTS_
			};

			this.api = Application.current.options.api;

			this.session = new Session({
				sessionToken:	Application.current.options.token,
			})


			this.title = '';

			/* Globals */
			Settings.location = '';

			response();
		});
	},

	factory: function(id) {
	},

	apis: {
	}
});